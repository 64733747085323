.table-container {
  margin: 0px 50px;
}

.generate-link {
  cursor: pointer;
  color: blue;
}

// .ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 8px 10px !important;
}

.ant-table-measure-row {
  display: none;
}
